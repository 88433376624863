import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const BgImage = styled(Img)`
  position: absolute !important;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out;
  img {
    transition: all 0.3s ease-out;
  }
`;

const FooterImage = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulAsset(contentful_id: { eq: "5hR7qCYTscyeMIKq0kKoai" }) {
          id
          fluid(maxWidth: 2650) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    `}
    render={data => (
      <BgImage
        fluid={data.contentfulAsset.fluid}
        alt={data.contentfulAsset.title}
      />
    )}
  />
);

export default FooterImage;
