import React from 'react';

import {
  FooterWrapper,
  FooterContainer,
  FooterContainerInner,
  FooterInner,
  FooterTop,
  FooterBottom,
  FooterImage,
  FooterMenus,
  ContactDetails,
  FooterReviews,
  FooterCopyright
} from './files';

const FooterIndex = () => (
  <FooterWrapper id="footer">
    <FooterImage />
    <FooterContainer>
      <FooterContainerInner>
        <FooterInner>
          <FooterTop>
            <FooterMenus />
            <ContactDetails />
          </FooterTop>
          <FooterBottom>
            <FooterReviews />
            <FooterCopyright />
          </FooterBottom>
        </FooterInner>
      </FooterContainerInner>
    </FooterContainer>
  </FooterWrapper>
);

export default FooterIndex;
