import React from 'react';

const LinkedInIcon = (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    className="linked-in-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>LinkedIn</title>
    <path d="M3.408 4.874H.186V15h3.222V4.874zm.205-3.127C3.603.757 2.92 0 1.816 0 .723 0 0 .756 0 1.747c0 .971.693 1.748 1.777 1.748h.02c1.123 0 1.826-.777 1.816-1.748zM15 9.197c0-3.107-1.582-4.558-3.701-4.558-1.738 0-2.5 1.012-2.92 1.706h.02V4.874H5.185s.039.95 0 10.126h3.212V9.35c0-.307.03-.603.108-.828.234-.603.762-1.226 1.65-1.226 1.162 0 1.631.93 1.631 2.288V15H15V9.196z" />
  </svg>
);

export default LinkedInIcon;
