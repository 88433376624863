import React from 'react';

const TripAdvisorIcon = (
  <svg
    width="24"
    height="13"
    className="trip-advisor-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>TripAdvisor</title>
    <path d="M6.781 7.471c0-.49-.437-.894-.968-.894-.542 0-.98.404-.98.894 0 .5.438.904.98.904.53 0 .968-.404.968-.904zm12.021-.01c0-.5-.437-.894-.98-.894-.54 0-.978.395-.978.895s.437.903.979.903.98-.403.98-.903zm-10.833.01c0 1.029-.907 1.856-2.01 1.856-1.115 0-2.011-.827-2.011-1.856 0-1.02.896-1.856 2.01-1.856 1.104 0 2.01.837 2.01 1.856zm12.01-.01c0 1.03-.896 1.856-2.01 1.856-1.104 0-2.01-.827-2.01-1.855 0-1.02.906-1.856 2.01-1.856 1.114 0 2.01.836 2.01 1.856zm-11.125.01c0-1.471-1.291-2.673-2.896-2.673-1.604 0-2.906 1.202-2.906 2.673 0 1.48 1.302 2.683 2.906 2.683S8.854 8.952 8.854 7.47zm12.021-.01c0-1.48-1.302-2.673-2.906-2.673-1.594 0-2.896 1.193-2.896 2.674 0 1.48 1.302 2.673 2.896 2.673 1.604 0 2.906-1.193 2.906-2.673zm-10.042.03c0 2.451-2.156 4.442-4.812 4.442-2.646 0-4.802-1.99-4.802-4.443 0-2.452 2.156-4.442 4.802-4.442 2.656 0 4.812 1.99 4.812 4.442zm6.959-5.51C14.572 2.067 12 4.5 12 7.49c0-3.049-2.667-5.51-5.969-5.52C7.74 1.288 9.865.913 12 .913c2.135 0 4.073.375 5.792 1.068zm4.99 5.51c0 2.451-2.147 4.442-4.803 4.442s-4.812-1.99-4.812-4.443c0-2.452 2.156-4.442 4.812-4.442s4.802 1.99 4.802 4.442zM20.01 2.047C17.97.788 15.187 0 12 0 8.812 0 5.844.788 3.802 2.048H0c.625.673 1.083 1.577 1.198 2.202C.479 5.163.052 6.279.052 7.49.052 10.538 2.73 13 6.021 13c1.875 0 3.552-.788 4.646-2.038.448.48 1.187 1.451 1.333 1.73.854-1.182 1.344-1.72 1.344-1.72 1.094 1.24 2.76 2.028 4.635 2.028 3.292 0 5.969-2.462 5.969-5.51 0-1.211-.427-2.327-1.146-3.24.115-.625.573-1.529 1.198-2.202h-3.99z" />
  </svg>
);

export default TripAdvisorIcon;
