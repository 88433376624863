import React, { Fragment } from 'react';

// Components
import {
  HeaderWrapper,
  HeaderLeft,
  HeaderLogo,
  HeaderRight,
  HeaderMobile
} from './files';

const HeaderIndex = ({
  isScrolling,
  menuOpen,
  menuToggle,
  bookingOpen,
  bookingToggle
}) => (
  <Fragment>
    <HeaderWrapper
      id="header"
      className={`${!isScrolling ? 'transparent' : ''} ${
        menuOpen ? 'menuOpen' : ''
      }`}
    >
      <HeaderLeft menuOpen={menuOpen} menuToggle={menuToggle} />
      <HeaderLogo menuOpen={menuOpen} />
      <HeaderRight
        bookingOpen={bookingOpen}
        transparent={!isScrolling}
        bookingToggle={bookingToggle}
      />
    </HeaderWrapper>
    <HeaderMobile bookingToggle={bookingToggle} />
  </Fragment>
);

export default HeaderIndex;
