import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Icons
import { EnquireIcon, BookingIcon } from '../../../../assets/images/icons';

const Wrapper = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.colors.global.background.light};
  box-shadow: ${props => props.theme.colors.header.shadow.dark};
  z-index: 11;
  @media (max-width: ${props => props.theme.responsive.small}) {
    display: flex;
  }
`;

const NavLink = styled(Link)`
  width: 50%;
  font-family: ${props => props.theme.fonts.header};
  color: ${props => props.theme.colors.global.base.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5em;
  position: relative;
  height: 3.5em;
  border-right: 1px solid ${props => props.theme.colors.global.border.dark};
  svg {
    width: 28px;
    height: 28px;
    margin-right: 0.5em;
    path {
      fill: ${props => props.theme.colors.global.base.dark};
      transition: all 0.3s ease-out;
    }
  }
`;

const NavAnchor = styled.a`
  width: 50%;
  font-family: ${props => props.theme.fonts.header};
  color: ${props => props.theme.colors.global.base.dark};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5em;
  position: relative;
  height: 3.5em;
  svg {
    width: 28px;
    height: 28px;
    margin-right: 0.5em;
    path {
      stroke: ${props => props.theme.colors.global.base.dark};
      transition: all 0.3s ease-out;
    }
  }
`;

const MobileHeader = ({ bookingToggle }) => (
  <Wrapper>
    <NavLink to="/enquire">
      {EnquireIcon}
      Enquire Now
    </NavLink>
    <NavAnchor href="javascript:;" onClick={bookingToggle}>
      {BookingIcon}
      Make a Booking
    </NavAnchor>
  </Wrapper>
);

export default MobileHeader;
