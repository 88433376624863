import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const PageLinks = styled.div`
  width: 50%;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 100%;
    float: none;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
  }
  ul {
    li {
      a {
        color: ${props => props.theme.colors.global.base.grey};
        @media (max-width: ${props => props.theme.responsive.medium}) {
          opacity: 0;
          transition-delay: 0s;
        }
      }
    }
  }
  h5 {
    margin-bottom: 1rem;
    font-size: 1.25em;
    color: ${props => props.theme.colors.global.primary};
    @media (max-width: ${props => props.theme.responsive.medium}) {
      opacity: 0;
      transition-delay: 0s;
      margin-top: 2rem;
      text-transform: uppercase;
    }
  }
  &.active {
    display: block;
    visibility: visible;
    opacity: 1;
    max-height: 100%;
    transition: max-height 0.25s ease-in;
    ul {
      li {
        a {
          opacity: 1;
          transition-delay: 0.5s;
        }
      }
    }
    h5 {
      opacity: 1;
      transition-delay: 0.5s;
    }
  }
`;

const PageMenu = props => (
  <StaticQuery
    query={graphql`
      query {
        contentfulMenus(id: { eq: "6c67afce-1f9c-5747-b099-5276b55c8a91" }) {
          id
          pages {
            id
            slug
            menuTitle
          }
        }
      }
    `}
    render={data => (
      <PageLinks className={props.footerMenu ? 'active' : ''}>
        <h5>Pages</h5>
        <ul className="footerMenu">
          {data.contentfulMenus.pages.map(page => (
            <li key={page.id}>
              <Link to={`/${page.slug}/`}>{page.menuTitle}</Link>
            </li>
          ))}
          <li>
            <Link to="/stories/">Stories</Link>
          </li>
        </ul>
      </PageLinks>
    )}
  />
);

export default PageMenu;
