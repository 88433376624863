import styled from 'styled-components';

const FooterContainerInner = styled.div`
  background: ${props => props.theme.colors.global.background.dark};
  padding: 0.75rem;
  position: relative;
  z-index: 1;
  margin-left: 8.333%;
  margin-right: 8.333%;
  transition: all 0.3s ease-out;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin: 0;
  }
`;

export default FooterContainerInner;
