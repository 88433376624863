import styled from 'styled-components';

const FooterContainer = styled.div`
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  max-width: 83.75rem;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s ease-out;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
`;

export default FooterContainer;
