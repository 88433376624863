import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

// Icons
import { ContactIcon } from '../../../../assets/images/icons';

const Wrapper = styled.h5`
  margin-bottom: 1rem;
  font-size: 1.25em;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin-top: 2rem;
    text-transform: uppercase;
  }
  a {
    display: flex;
    color: ${props => props.theme.colors.global.primary};
    align-items: center;
    justify-content: flex-end;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      justify-content: center;
    }
    svg {
      height: 1.25em;
      width: 1.25em;
      margin-left: 0.5em;
      margin-top: -0.25em;
      path {
        stroke: ${props => props.theme.colors.global.base.light};
        stroke-width: 2;
        fill: none;
      }
    }
  }
`;

const ContactTitle = () => (
  <Wrapper>
    <Link to="/contact">
      Contact Us
      {ContactIcon}
    </Link>
  </Wrapper>
);

export default ContactTitle;
