import styled from 'styled-components';

const Button = styled.button`
  border: 0;
  border-radius: 0;
  background: transparent;
  appearance: none;
  color: ${props => props.theme.colors.global.primary};
  text-transform: uppercase;
  padding: 0.75em 1em;
  font-size: 1.125rem;
  line-height: 1;
  cursor: pointer;
  width: auto;
  overflow: hidden;
  display: inline-block;
  position: relative;
  text-align: center;
  min-width: 10em;
  border: 1px solid ${props => props.theme.colors.global.primary};
  font-family: ${props => props.theme.fonts.header};
  outline: none;
  &:hover {
    border: 1px solid ${props => props.theme.colors.global.secondary};
    color: ${props => props.theme.colors.global.secondary};
  }
`;

export default Button;
