import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Icons
import { DatesArrowIcon } from '../../../../assets/images/icons';

const Wrapper = styled.span`
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    &:after {
      position: absolute;
      display: flex;
      top: 0;
      justify-content: center;
      align-items: center;
      right: 0;
      bottom: 0;
      left: 0;
      font-size: 1.75em;
      background: ${props => props.theme.colors.global.background.light};
      opacity: 0;
      visibility: hidden;
      content: 'Choose a Property';
      transition: all 0.3s ease-out;
    }
    &:hover {
      &:after {
        opacity: 0.9;
        visibility: visible;
      }
    }
  }
`;

const Widget = styled.div`
  width: calc(50% - 0.25em);
  font-family: ${props => props.theme.fonts.header};
  position: relative;
  &:first-child {
    margin-right: 0.25em;
  }
  &:last-child {
    margin-left: 0.25em;
  }
  .label {
    text-transform: uppercase;
    color: ${props => props.theme.colors.global.base.light};
    background: ${props => props.theme.colors.global.background.dark};
    font-size: 0.875em;
    padding: 0.5em;
    display: block;
  }
  .date-wrap {
    padding: 1em 0.5em 2em 0.5em;
    position: relative;
    border: 1px solid ${props => props.theme.colors.global.border.dark};
    margin-bottom: 1em;
    span {
      display: block;
      &.day {
        font-family: ${props => props.theme.fonts.body};
        font-size: 0.75em;
      }
      &.date {
        font-size: 2.6875em;
        line-height: 1;
      }
      &.month {
        font-family: ${props => props.theme.fonts.body};
        font-size: 0.75em;
      }
    }
    div {
      &.arrow {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 60%);
        background: ${props => props.theme.colors.global.background.light};
        width: 2em;
        height: 2em;
        svg {
          path {
            fill: ${props => props.theme.colors.global.base.dark};
          }
        }
      }
    }
  }
`;

const BookingDates = ({ isEnabled, toggleDates, startDate, endDate }) => (
  <Wrapper
    className={!isEnabled ? 'disabled' : ''}
    onClick={!isEnabled ? null : toggleDates}
  >
    <Widget>
      <div className="label">Arrival</div>
      <div className="date-wrap">
        <span className="day">{moment(startDate).format('ddd')}</span>
        <span className="date">{moment(startDate).format('DD')}</span>
        <span className="month">{moment(startDate).format('MMM')}</span>
        <div className="arrow">{DatesArrowIcon}</div>
      </div>
    </Widget>
    <Widget>
      <div className="label">Departure</div>
      <div className="date-wrap">
        <span className="day">{moment(endDate).format('ddd')}</span>
        <span className="date">{moment(endDate).format('DD')}</span>
        <span className="month">{moment(endDate).format('MMM')}</span>
        <div className="arrow">{DatesArrowIcon}</div>
      </div>
    </Widget>
  </Wrapper>
);

export default BookingDates;
