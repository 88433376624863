import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

// Icons
import { BookingDropdownIcon } from '../../../../assets/images/icons';

const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
  border: 0;
  border-bottom: 1px solid ${props => props.theme.colors.global.base.dark};
  cursor: pointer;
  margin-bottom: 1em;
  width: 100%;
  display: block;
  cursor: pointer;
  color: ${props => props.theme.colors.global.base.dark};
  background: ${props => props.theme.colors.global.background.light};
  &:after {
    width: 1.5rem;
    position: absolute;
    content: '';
    right: 0.5rem;
    top: calc(50% - 0.75rem);
    height: 1.5rem;
    z-index: 1;
    background-image: url(${BookingDropdownIcon});
    background-size: 100%;
    background-repeat: no-repeat;
  }
  select {
    width: 130%;
    appearance: none;
    background: transparent;
    color: ${props => props.theme.colors.global.base.dark};
    border-radius: 0;
    border: 0;
    padding: 0.75rem 0.5rem;
    line-height: 1;
    position: relative;
    z-index: 2;
    display: block;
    font-size: 1.1rem;
    cursor: pointer;
    font-family: ${props => props.theme.fonts.header};
    &:focus {
      outline: 0;
    }
  }
`;

const BookingSelect = props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulProperties(sort: { fields: [menuOrder], order: ASC }) {
          edges {
            node {
              id
              seoContent {
                menuTitle
              }
              information {
                littleHotelierId
              }
              menuOrder
            }
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <select
          className="booking-select"
          value={props.littleHotelierId}
          onChange={props.handlePropertySelect}
        >
          <option value="">Choose a Property</option>
          {data.allContentfulProperties.edges.map(({ node: property }) => (
            <option
              key={property.id}
              value={property.information.littleHotelierId}
            >
              {property.seoContent.menuTitle}
            </option>
          ))}
        </select>
      </Wrapper>
    )}
  />
);

export default BookingSelect;
