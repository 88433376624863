import React from 'react';
import styled from 'styled-components';
import { Link, StaticQuery, graphql } from 'gatsby';
import moment from 'moment';

// Icons
import { DoubleArrowIcon } from '../../../../assets/images/icons';

const Wrapper = styled.div`
  width: 50%;
  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 100%;
    float: none;
  }
  p {
    margin: 0;
    font-size: 1em;
    padding-top: 0.5rem;
    margin-bottom: 5px;
    display: grid;
    font-family: ${props => props.theme.fonts.header};
    text-align: right;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      padding-top: 1rem;
      text-align: center;
    }
    a {
      position: relative;
      color: ${props => props.theme.colors.global.base.grey};
      @media (max-width: ${props => props.theme.responsive.small}) {
        display: block;
        margin-left: 0;
        padding-left: 0;
      }
      &:hover {
        color: ${props => props.theme.colors.global.primary};
      }
      svg {
        padding-right: 10px;
        height: 10px;
        path {
          fill: ${props => props.theme.colors.global.base.grey};
        }
      }
      &:last-child {
        margin-top: 15px;
        color: ${props => props.theme.colors.global.base.light};
        &:hover {
          color: ${props => props.theme.colors.global.primary};
        }
      }
    }
  }
`;

const FooterCopyright = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulCompanyInformation(
          id: { eq: "bb2bf43d-b156-5f8d-acca-daa9163189cd" }
        ) {
          companyName
        }
        contentfulMenus(id: { eq: "c98d1d20-83f4-52c3-911c-11e932fb4deb" }) {
          id
          pages {
            id
            slug
            menuTitle
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <p className="copyright">
          {data.contentfulMenus.pages.map(page => (
            <Link key={page.id} to={`/${page.slug}/`}>
              {DoubleArrowIcon}
              {page.menuTitle}
            </Link>
          ))}
          <a
            href="https://discovrbookings.com"
            target="_blank"
            rel="noopener noreferrer"
            title="Hotel Internet Marketing"
          >
            {DoubleArrowIcon}
            Made by Discovr Bookings
          </a>
          <Link to="/">
            © {moment().format('YYYY')}&nbsp;
            {data.contentfulCompanyInformation.companyName}
          </Link>
        </p>
      </Wrapper>
    )}
  />
);

export default FooterCopyright;
