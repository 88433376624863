import React from 'react';
import styled from 'styled-components';

// Components
import { ToggleMenu, PropertyMenu, PageMenu } from '.';

const Wrapper = styled.div`
  width: 50%;
  display: flex;
  transition: all 0.3s ease-out;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 100%;
    float: none;
    -webkit-box-ordinal-group: 3;
    order: 2;
    display: block;
    text-align: center;
  }
`;

class FooterMenus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      footerMenu: false
    };
    this.handleToggleFooterMenu = this.handleToggleFooterMenu.bind(this);
  }

  handleToggleFooterMenu() {
    this.setState(state => ({
      footerMenu: !state.footerMenu
    }));
  }

  render() {
    const { footerMenu } = this.state;
    return (
      <Wrapper>
        <ToggleMenu
          footerMenu={footerMenu}
          handleToggleFooterMenu={this.handleToggleFooterMenu}
        />
        <PropertyMenu footerMenu={footerMenu} />
        <PageMenu footerMenu={footerMenu} />
      </Wrapper>
    );
  }
}

export default FooterMenus;
