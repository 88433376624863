import React from 'react';

const ContactIcon = (
  <svg
    viewBox="0 0 48 48"
    height="18"
    width="18"
    id="contact-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Contact Mountain Whispers | Blue Mountains, NSW Australia</title>
    <path d="M40.1655,18.1655C40.1655,27.0934,24,44,24,44S7.8345,27.0934,7.8345,18.1655a16.1655,16.1655,0,0,1,32.331,0ZM28.019,17.4442A4.019,4.019,0,1,1,24,13.4252,4.019,4.019,0,0,1,28.019,17.4442Z" />
  </svg>
);

export default ContactIcon;
