import React from 'react';

const DatesArrowIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 600 600"
    id="date-arrow-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Booking Dates</title>
    <path d="M300 481.25L71.05 118.75l237.063 90.625L528.95 118.75z" />
  </svg>
);

export default DatesArrowIcon;
