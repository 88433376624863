import styled from 'styled-components';

const FooterTop = styled.div`
  display: flex;
  margin-bottom: 2rem;
  transition: all 0.3s ease-out;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin-bottom: 1rem;
  }
`;

export default FooterTop;
