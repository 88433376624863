import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

const Wrapper = styled.p`
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.global.base.grey};
  font-size: 1em;
  font-family: ${props => props.theme.fonts.header};
  justify-content: flex-end;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin-bottom: 0.5rem;
  }
`;

const ContactAddress = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulCompanyInformation(
          id: { eq: "bb2bf43d-b156-5f8d-acca-daa9163189cd" }
        ) {
          address
          city
          state
          postcode
        }
      }
    `}
    render={data => (
      <Wrapper>
        {data.contentfulCompanyInformation.address}
        ,&nbsp;
        {data.contentfulCompanyInformation.city}
        .&nbsp;
        {data.contentfulCompanyInformation.state}
        &nbsp;
        {data.contentfulCompanyInformation.postcode}.
      </Wrapper>
    )}
  />
);

export default ContactAddress;
