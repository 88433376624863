import { keyframes } from 'styled-components';

const TextLoading = keyframes`
  0% {
    background-position: 0% 50%;
  }
	100% {
    background-position: 100% 50%;
  }
`;

export default TextLoading;
