import React from 'react';

const FacebookIcon = (
  <svg
    width="9"
    height="17"
    viewBox="0 0 9 17"
    className="facebook-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Facebook</title>
    <path d="M9 .123C8.719.082 7.75 0 6.625 0 4.271 0 2.656 1.41 2.656 3.995v2.227H0v3.024h2.656V17h3.188V9.246H8.49l.406-3.024H5.844V4.29c0-.869.24-1.471 1.52-1.471H9V.123z" />
  </svg>
);

export default FacebookIcon;
