import styled from 'styled-components';

const MenuWrapper = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 150%;
  overflow: hidden;
  background: ${props => props.theme.colors.global.background.dark};
  padding-left: 1.875em;
  display: flex;
  align-items: center;
  z-index: 15;
  will-change: transform;
  min-height: 30em;
  transition: all 1s cubic-bezier(0.59, 0, 0.06, 1);
  &.menu-open {
    transform: translateY(150%);
  }
  @media (max-width: ${props => props.theme.responsive.small}) {
    padding-left: 1em;
    padding-right: 1em;
  }
`;

export default MenuWrapper;
