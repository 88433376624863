import React from 'react';

const defaultContext = {
  data: {
    headerTransparent: false
  },
  set: () => {}
};

const { Provider, Consumer } = React.createContext(defaultContext);

class ContextProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      ...defaultContext,
      set: this.setData // eslint-disable-line react/no-unused-state
    };
  }

  setData = newData => {
    this.setState(state => ({
      data: {
        ...state.data,
        ...newData
      }
    }));
  };

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

export { Consumer as default, ContextProvider };
