const theme = {
  colors: {
    global: {
      background: {
        darker: '#010101', // background darker
        dark: '#101010', // background dark
        light: '#fcfcfc', // background light
        overlay: 'rgba(0,0,0,0.6)', // background opaque overlay
        hover: '#222222' // background hover overlay
      },
      base: {
        dark: '#222222', // text on light
        light: '#ffffff', // text on dark
        grey: '#aaaaaa' // text for both
      },
      primary: '#e7b679', // beige
      secondary: '#4da781', // green
      border: {
        dark: 'rgba(0,67,78,0.2)', // border on light
        light: 'rgba(255,255,255,0.2)' // border on dark
      },
      notice: {
        error: 'rgba(228,58,45,1)' // error
      },
      shadow: {
        dark: 'rgba(0, 0, 0, 0.6)'
      }
    },
    header: {
      background: '#101010', // background
      transparent: 'rgba(0,0,0,0.2)', // transparent background
      shadow: {
        dark: '0 -2px 8px 0 rgba(0, 67, 78, 0.05)' // box shadow on light
      }
    }
  },
  fonts: {
    body: `'Lafabrique', 'Franklin Gothic Medium', 'Franklin Gothic', 'ITC Franklin Gothic', 'Gill Sans', 'Gill Sans MT',sans-serif;`,
    header: `'ModernMTProDisplay', 'Didot', 'Bodoni MT', 'Georgia', serif;`,
    style: {
      shadow: {
        dark: '0px 2px 4px rgba(0, 0, 0, 0.2)' // text shadow on light
      }
    }
  },
  responsive: {
    small: '48em',
    medium: '64rem',
    large: '81.25em',
    xlarge: '91em',
    xxlarge: '105em'
  }
};

export default theme;
