import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

// Icons
import {
  FacebookIcon,
  TwitterIcon,
  InstagramIcon,
  PinterestIcon,
  YoutubeIcon,
  LinkedInIcon,
  TripAdvisorIcon
} from '../../../../assets/images/icons';

const Wrapper = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    justify-content: center;
    padding: 1.5rem 0;
    border-bottom: 1px solid ${props => props.theme.colors.global.border.light};
  }
  a {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    svg {
      path {
        fill: ${props => props.theme.colors.global.base.light};
      }
    }
    &:hover {
      svg {
        path {
          fill: ${props => props.theme.colors.global.primary};
        }
      }
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

const ContactSocial = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulCompanyInformation(
          id: { eq: "bb2bf43d-b156-5f8d-acca-daa9163189cd" }
        ) {
          facebookUrl
          twitterUrl
          youtubeUrl
          instagramUrl
          linkedInUrl
          pinterestUrl
        }
      }
    `}
    render={data => (
      <Wrapper>
        <a
          href={data.contentfulCompanyInformation.facebookUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {FacebookIcon}
        </a>
        <a
          href={data.contentfulCompanyInformation.twitterUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {TwitterIcon}
        </a>
        <a
          href={data.contentfulCompanyInformation.instagramUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {InstagramIcon}
        </a>
        <a
          href={data.contentfulCompanyInformation.pinterestUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {PinterestIcon}
        </a>
        <a
          href={data.contentfulCompanyInformation.youTubeUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {YoutubeIcon}
        </a>
        <a
          href={data.contentfulCompanyInformation.linkedInUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {LinkedInIcon}
        </a>
        <a
          href="https://www.tripadvisor.com.au/RentalPropertyManager-a_manager.1726?"
          target="_blank"
          rel="noopener noreferrer"
        >
          {TripAdvisorIcon}
        </a>
      </Wrapper>
    )}
  />
);

export default ContactSocial;
