// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-property-jsx": () => import("/opt/build/repo/src/templates/property.jsx" /* webpackChunkName: "component---src-templates-property-jsx" */),
  "component---src-templates-story-tags-jsx": () => import("/opt/build/repo/src/templates/storyTags.jsx" /* webpackChunkName: "component---src-templates-story-tags-jsx" */),
  "component---src-templates-stories-jsx": () => import("/opt/build/repo/src/templates/stories.jsx" /* webpackChunkName: "component---src-templates-stories-jsx" */),
  "component---src-templates-story-jsx": () => import("/opt/build/repo/src/templates/story.jsx" /* webpackChunkName: "component---src-templates-story-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-awards-accolades-jsx": () => import("/opt/build/repo/src/pages/about/awards-accolades.jsx" /* webpackChunkName: "component---src-pages-about-awards-accolades-jsx" */),
  "component---src-pages-about-guest-reviews-jsx": () => import("/opt/build/repo/src/pages/about/guest-reviews.jsx" /* webpackChunkName: "component---src-pages-about-guest-reviews-jsx" */),
  "component---src-pages-about-index-jsx": () => import("/opt/build/repo/src/pages/about/index.jsx" /* webpackChunkName: "component---src-pages-about-index-jsx" */),
  "component---src-pages-about-media-jsx": () => import("/opt/build/repo/src/pages/about/media.jsx" /* webpackChunkName: "component---src-pages-about-media-jsx" */),
  "component---src-pages-about-mw-vision-jsx": () => import("/opt/build/repo/src/pages/about/mw-vision.jsx" /* webpackChunkName: "component---src-pages-about-mw-vision-jsx" */),
  "component---src-pages-about-sustainability-jsx": () => import("/opt/build/repo/src/pages/about/sustainability.jsx" /* webpackChunkName: "component---src-pages-about-sustainability-jsx" */),
  "component---src-pages-celebrations-jsx": () => import("/opt/build/repo/src/pages/celebrations.jsx" /* webpackChunkName: "component---src-pages-celebrations-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-enquire-jsx": () => import("/opt/build/repo/src/pages/enquire.jsx" /* webpackChunkName: "component---src-pages-enquire-jsx" */),
  "component---src-pages-events-jsx": () => import("/opt/build/repo/src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-experiences-jsx": () => import("/opt/build/repo/src/pages/experiences.jsx" /* webpackChunkName: "component---src-pages-experiences-jsx" */),
  "component---src-pages-gallery-jsx": () => import("/opt/build/repo/src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-gift-vouchers-jsx": () => import("/opt/build/repo/src/pages/gift-vouchers.jsx" /* webpackChunkName: "component---src-pages-gift-vouchers-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-indulgences-jsx": () => import("/opt/build/repo/src/pages/indulgences.jsx" /* webpackChunkName: "component---src-pages-indulgences-jsx" */),
  "component---src-pages-mw-collection-jsx": () => import("/opt/build/repo/src/pages/mw-collection.jsx" /* webpackChunkName: "component---src-pages-mw-collection-jsx" */),
  "component---src-pages-offers-jsx": () => import("/opt/build/repo/src/pages/offers.jsx" /* webpackChunkName: "component---src-pages-offers-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("/opt/build/repo/src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-jsx": () => import("/opt/build/repo/src/pages/terms.jsx" /* webpackChunkName: "component---src-pages-terms-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

