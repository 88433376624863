import React from 'react';

const CloseIcon = (
  <svg viewBox="0 0 48 48" id="close-icon" xmlns="http://www.w3.org/2000/svg">
    <title>Close Booking</title>
    <line
      x1="40"
      y1="8"
      x2="8"
      y2="40"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <line
      x1="40"
      y1="40"
      x2="8"
      y2="8"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

export default CloseIcon;
