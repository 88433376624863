import styled from 'styled-components';

const FooterInner = styled.div`
  padding: 2.5em 3rem;
  border: 1px solid ${props => props.theme.colors.global.border.light};
  transition: all 0.3s ease-out;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    text-align: center;
    padding: 1.5em 1.25rem;
  }
`;

export default FooterInner;
