import { createGlobalStyle } from 'styled-components';
import { Fonts } from './files';

export default createGlobalStyle`

  ${Fonts}

  html, body {
    -webkit-font-smoothing: antialiased;
  }

  html {
    background: ${props => props.theme.colors.global.background.dark};
    display: block;
    font-size: 100%;
    @media (min-width: ${props => props.theme.responsive.xxlarge}) {
      font-size: 112.5%;
    }
    @media (max-width: ${props => props.theme.responsive.xxlarge}) {
      font-size: 106.25%;
    }
    @media (max-width: ${props => props.theme.responsive.xlarge}) {
      font-size: 100%;
    }
    @media (max-width: ${props => props.theme.responsive.medium}) {
      font-size: 87.5%;
    }
  }

  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  body {
    background: ${props => props.theme.colors.global.background.dark};
    line-height: 1.5;
    font-weight: 400;
    color: ${props => props.theme.colors.global.base.grey};
    font-family: ${props => props.theme.fonts.body};
    overflow-x: hidden;
    max-width: 100%;
  }

  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }

  a, input, textarea, button, header {
    transition: all 0.3s ease-out;
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.global.primary};
    position: relative;
    &:hover {
      color: ${props => props.theme.colors.global.secondary};
    }
  }

  strong, b, i, em {
    font-weight: bold;
  }

  p {
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    font-family: ${props => props.theme.fonts.body};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${props => props.theme.fonts.header};
    font-weight: normal;
  }

  h1 {
    font-size: 3.5625em;
    line-height: 1.12280701754386;
    margin-bottom: 1.5rem;
    @media (max-width: ${props => props.theme.responsive.small}) {
      font-size: 2.357em;
      line-height: 1.060606060606061;
    }
}

  h2 {
    font-size: 2.6875em;
    line-height: 1.116279069767442;
    margin-bottom: 1.5rem;
    @media (max-width: ${props => props.theme.responsive.small}) {
      font-size: 1.643em;
      line-height: 1.217391304347826;
    }
  }

  h3 {
    font-size: 2em;
    line-height: 1.25;
    margin-bottom: 1.5rem;
    @media (max-width: ${props => props.theme.responsive.small}) {
      font-size: 2em;
      line-height: 1.125;
    }
  }

  h4 {
    font-size: 1.5rem;
    line-height: 1.333333333333333;
    margin-bottom: 1.5rem;
    @media (max-width: ${props => props.theme.responsive.small}) {
      font-size: 1.357rem;
      line-height: 1.289473684210526;
    }
  }

  h5 {
    font-size: 1.125em;
    line-height: 1.333333333333333;
    margin-bottom: 1.5rem;
    @media (max-width: ${props => props.theme.responsive.small}) {
      font-size: 1.357em;
      line-height: 1.289473684210526;
    }
  }

  h6 {
    font-size: 1.125em;
    line-height: 1.333333333333333;
    margin-bottom: 1.5rem;
    @media (max-width: ${props => props.theme.responsive.small}) {
      font-size: 1.357em;
      line-height: 1.289473684210526;
    }
  } 

  ol, ul {
    list-style: none;
  }

  ul {
    margin-bottom: 1.5rem;
    text-align: left;
    li {
      font-size: 1em;
      margin-bottom: 0.75em;
      padding-bottom: 0.75em;
      padding-left: 1.75em;
      position: relative;
      line-height: 1.5;
      border-bottom: 1px solid ${props =>
        props.theme.colors.global.border.light};
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .button, button, input[type=submit] {
    border: 0;
    border-radius: 0;
    background: transparent;
    appearance: none;
    color: ${props => props.theme.colors.global.primary};
    text-transform: uppercase;
    padding: 0.75em 1em;
    font-size: 1.125rem;
    line-height: 1;
    cursor: pointer;
    width: auto;
    overflow: hidden;
    display: inline-block;
    position: relative;
    text-align: center;
    min-width: 10em;
    border: 1px solid ${props => props.theme.colors.global.primary};
    font-family: ${props => props.theme.fonts.body};
    @media (max-width: ${props => props.theme.responsive.small}) {
      min-width: 9em;
    }
}

  .clearfix:before,
  .clearfix:after {
    content: '';
    display: table;
  }

  input, textarea {
    border: 0;
    padding: 1em 0;
    font-size: 1em;
    width: 100%;
    border-radius: 0;
    margin-bottom: 1em;
    box-shadow: none;
    color: ${props => props.theme.colors.global.base.dark};
    font-weight: 300;
    background: none;
    font-family: ${props => props.theme.fonts.body};
    border-bottom: 1px solid ${props => props.theme.colors.global.border.light};
    position: relative;
    z-index: 2;
  }

  input[type=text], textarea[type=text] {
    appearance: none;
  }

  /* Consent Manager Overlay */
  .e160cov61 {
    .e160cov63 {
      h2 {
        margin-bottom: 0;
        font-family: system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto, Helvetica,Arial,sans-serif,'Apple Color Emoji','Segoe UI Emoji', 'Segoe UI Symbol';
        font-weight: 400;
      }
    }
    .e160cov62 {
      .css-7eturq-Content {
        font-size: 13px;
        line-height: 1.5
      }
      th, td {
        &:last-child {
          display: none;
        }
      }
      input {
        padding: 0;
        width: auto;
        margin-bottom: 6px;
        margin-top: -1px;
      }
      button {
        &:focus {
          box-shadow: inset 0 0 0 1px rgba(67,90,111,0.146), inset 0 -1px 1px 0 rgba(67,90,111,0.079);
        }
      }
      button[type="submit"] {
        padding: 0 16px;
        border: none;
        border-radius: 4px;
        color: inherit;
        line-height: 1;
        cursor: pointer;
        transition: box-shadow 80ms ease-in-out;
        background-color: ${props => props.theme.colors.global.secondary};
        background-image: none;
        color: ${props => props.theme.colors.global.base.light};
        vertical-align: baseline;
      }
    }
  }
`;
