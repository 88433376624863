import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { BookingWrapper, BookingClose, BookingForm } from './files';

const BookingIndex = ({ location, bookingOpen, bookingToggle }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulProperties(sort: { fields: [menuOrder], order: ASC }) {
          edges {
            node {
              id
              seoContent {
                slug
              }
              information {
                littleHotelierId
              }
              menuOrder
            }
          }
        }
      }
    `}
    render={data => {
      const properties = data.allContentfulProperties;
      return (
        <BookingWrapper
          id="booking"
          className={bookingOpen ? 'booking-open' : ''}
        >
          <BookingClose bookingToggle={bookingToggle} />
          <BookingForm location={location} properties={properties} />
        </BookingWrapper>
      );
    }}
  />
);

export default BookingIndex;
