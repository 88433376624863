import React from 'react';

const ArrowRightIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 48 48"
    id="hero-arrow-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Go to Property</title>
    <polygon points="38.5 24 9.5 42.316 16.75 23.351 9.5 5.684 38.5 24" />
  </svg>
);

export default ArrowRightIcon;
