import styled from 'styled-components';

const FooterWrapper = styled.footer`
  background: ${props => props.theme.colors.global.background.dark};
  color: ${props => props.theme.colors.global.base.light};
  padding: 6em 0 3.75em 0;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-out;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    padding-bottom: 5.5rem;
  }
  ul {
    padding: 0;
    margin: 0;
    width: 100%;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      margin-bottom: 0.75em;
    }
    li {
      padding: 0;
      border: 0;
      @media (max-width: ${props => props.theme.responsive.medium}) {
        text-align: center;
      }
      a {
        display: block;
        font-family: ${props => props.theme.fonts.header};
        font-size: 1.125em;
      }
    }
  }
  a {
    color: ${props => props.theme.colors.global.base.light};
    &:hover {
      color: ${props => props.theme.colors.global.primary};
    }
  }
`;

export default FooterWrapper;
