import styled from 'styled-components';

const BookingWrapper = styled.div`
  position: fixed;
  top: 4.125em;
  right: 0;
  z-index: 10;
  background: ${props => props.theme.colors.global.background.light};
  padding: 1em;
  width: 19.5em;
  opacity: 0;
  visibility: hidden;
  color: ${props => props.theme.colors.global.base.dark};
  transition: all 0.3s ease;
  transform-origin: left top;
  transform: scaleY(0);
  @media (max-width: ${props => props.theme.responsive.small}) {
    top: auto;
    bottom: 3.5em;
    width: 100%;
    padding-top: 3em;
    transform: translateY(100%);
    &.booking-open {
      transform: translateY(0);
    }
  }
  &.booking-open {
    opacity: 1;
    visibility: visible;
    transform: scaleY(1);
  }
`;

export default BookingWrapper;
