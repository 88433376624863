import React from 'react';

const ScrollLeftIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 600 600"
    id="scroll-left-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Scroll Left</title>
    <path d="M118.75 300l362.5-228.95-90.625 237.063L481.25 528.95z" />
  </svg>
);

export default ScrollLeftIcon;
