import React from 'react';

const YoutubeIcon = (
  <svg
    width="25"
    height="24"
    viewBox="0 0 48 48"
    className="youtube-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Youtube</title>
    <path d="M33,24a1.4546,1.4546,0,0,1-.7031,1.2656l-12,7.5A1.38,1.38,0,0,1,19.5,33a1.5953,1.5953,0,0,1-.7266-.1875A1.5177,1.5177,0,0,1,18,31.5v-15a1.5177,1.5177,0,0,1,.7734-1.3125,1.4827,1.4827,0,0,1,1.5235.0469l12,7.5A1.4546,1.4546,0,0,1,33,24Zm12,0a47.53,47.53,0,0,0-.7266-10.1719,5.3726,5.3726,0,0,0-4.5468-4.2422A140.6946,140.6946,0,0,0,24,9a140.6946,140.6946,0,0,0-15.7266.5859A5.3463,5.3463,0,0,0,3.75,13.8281,46.0786,46.0786,0,0,0,3,24a47.53,47.53,0,0,0,.7266,10.1719,5.3728,5.3728,0,0,0,4.5468,4.2422A140.6946,140.6946,0,0,0,24,39a140.6946,140.6946,0,0,0,15.7266-.5859A5.3207,5.3207,0,0,0,44.25,34.1719,46.0786,46.0786,0,0,0,45,24Z" />
  </svg>
);

export default YoutubeIcon;
