import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { ConsentManager } from '@segment/consent-manager';
// import inEU from '@segment/in-eu';

const ConsentBar = styled.div`
  .exqsvii0 {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: ${props => props.theme.colors.global.background.darker};
    .exqsvii1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: ${props => props.theme.responsive.small}) {
        flex-direction: column;
      }
      p {
        text-align: left;
        padding: 1em;
        font-size: 13px;
        letter-spacing: 0px;
        line-height: 1.7;
        margin-bottom: 0;
        @media screen and (max-width: ${props =>
            props.theme.responsive.small}) {
          text-align: center;
        }
        &.exqsvii2 {
          button {
            cursor: pointer;
            white-space: nowrap;
            width: 100%;
            text-transform: capitalize;
            font-size: 1.125rem;
            font-family: ${props => props.theme.fonts.header};
            background-color: ${props =>
              props.theme.colors.global.background.overlay};
            color: ${props => props.theme.colors.global.base.light};
            text-shadow: ${props => props.theme.fonts.style.shadow.dark};
            padding: 0.5rem 1rem;
            display: block;
            text-decoration: none;
            transition: all 300ms ease-in-out;
            &:after {
              position: absolute;
              left: 0;
              width: 100%;
              bottom: 0;
              height: 1px;
              background: ${props => props.theme.colors.global.primary};
              content: '';
              z-index: -1;
              transition: all 0.6s ease-out;
            }
            &:hover {
              background: transparent;
              color: ${props => props.theme.colors.global.base.light};
              &:after {
                height: 100%;
              }
            }
          }
        }
      }
    }
    button {
      &.exqsvii3 {
        min-width: auto;
      }
    }
  }
`;

export default function() {
  const bannerContent = (
    <span>
      We use cookies to collect data to improve your experience on our site. By
      using our website, you’re agreeing to the collection of data as described
      in our{' '}
      <Link
        to="/privacy-policy /"
        title="Website Privacy Policy"
        aria-label="Website Privacy Policy"
      >
        Privacy Policy
      </Link>
      .
    </span>
  );
  const bannerSubContent = 'Change Preferences';
  const preferencesDialogTitle = 'Website Data Collection Preferences';
  const preferencesDialogContent =
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.';
  const cancelDialogTitle = 'Are you sure you want to cancel?';
  const cancelDialogContent =
    'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.';

  return (
    <ConsentBar>
      <ConsentManager
        writeKey={process.env.GATSBY_SEGMENT_WRITE_KEY}
        // shouldRequireConsent={inEU}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
      />
    </ConsentBar>
  );
}
