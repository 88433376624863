import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  &.booking-open {
    opacity: 1;
    visibility: visible;
    z-index: 9;
  }
`;

const Freezer = ({ bookingOpen, bookingToggle }) => (
  <Wrapper
    onClick={bookingToggle}
    className={bookingOpen ? 'booking-open' : ''}
  />
);

export default Freezer;
