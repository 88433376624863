import React from 'react';

const TwitterIcon = (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    className="twitter-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Twitter</title>
    <path d="M18 1.781a7.249 7.249 0 0 1-2.124.586A3.759 3.759 0 0 0 17.497.281a7.151 7.151 0 0 1-2.34.914A3.637 3.637 0 0 0 12.46 0c-2.045 0-3.69 1.7-3.69 3.785 0 .293.035.586.092.867-3.061-.164-5.79-1.664-7.607-3.96a3.832 3.832 0 0 0-.502 1.91 3.81 3.81 0 0 0 1.644 3.152 3.645 3.645 0 0 1-1.667-.48v.046c0 1.84 1.268 3.364 2.958 3.715-.308.082-.64.13-.97.13-.24 0-.469-.024-.697-.06.468 1.5 1.827 2.59 3.449 2.625a7.27 7.27 0 0 1-4.58 1.618c-.309 0-.594-.012-.891-.047A10.233 10.233 0 0 0 5.665 15c6.784 0 10.496-5.766 10.496-10.77 0-.164 0-.328-.011-.492A8.047 8.047 0 0 0 18 1.781z" />
  </svg>
);

export default TwitterIcon;
