import React from 'react';

const InstagramIcon = (
  <svg
    width="17"
    height="17"
    className="instagram-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Instagram</title>
    <path d="M11.333 8.5A2.839 2.839 0 0 1 8.5 11.333 2.839 2.839 0 0 1 5.667 8.5 2.839 2.839 0 0 1 8.5 5.667 2.839 2.839 0 0 1 11.333 8.5zm1.527 0A4.355 4.355 0 0 0 8.5 4.14 4.355 4.355 0 0 0 4.14 8.5a4.355 4.355 0 0 0 4.36 4.36 4.355 4.355 0 0 0 4.36-4.36zm1.196-4.537c0-.565-.454-1.019-1.019-1.019-.564 0-1.018.454-1.018 1.019 0 .564.454 1.018 1.018 1.018.565 0 1.019-.454 1.019-1.018zM8.5 1.528c1.24 0 3.896-.1 5.013.343.388.155.675.343.974.642.299.299.487.586.642.974.443 1.117.343 3.774.343 5.013 0 1.24.1 3.896-.343 5.013a2.717 2.717 0 0 1-.642.974 2.717 2.717 0 0 1-.974.642c-1.117.443-3.774.343-5.013.343-1.24 0-3.896.1-5.013-.343a2.717 2.717 0 0 1-.974-.642 2.717 2.717 0 0 1-.642-.974c-.443-1.117-.343-3.774-.343-5.013 0-1.24-.1-3.896.343-5.013.155-.388.343-.675.642-.974.299-.299.586-.487.974-.642 1.117-.443 3.774-.343 5.013-.343zM17 8.5c0-1.173.01-2.335-.056-3.508-.066-1.361-.376-2.568-1.372-3.564C14.576.432 13.369.122 12.008.056 10.835-.01 9.673.001 8.5.001S6.165-.01 4.992.056C3.63.122 2.424.432 1.428 1.428.432 2.424.122 3.631.056 4.992-.01 6.165.001 7.327.001 8.5s-.011 2.335.055 3.508c.066 1.361.376 2.568 1.372 3.564.996.996 2.203 1.306 3.564 1.372 1.173.066 2.335.055 3.508.055s2.335.011 3.508-.055c1.361-.066 2.568-.376 3.564-1.372.996-.996 1.306-2.203 1.372-3.564.066-1.173.055-2.335.055-3.508z" />
  </svg>
);

export default InstagramIcon;
