import React from 'react';

const PinterestIcon = (
  <svg
    width="14"
    height="18"
    viewBox="0 0 14 18"
    className="pinterest-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Pinterest</title>
    <path d="M0 6.458c0 1.417.569 3.483 2.133 3.948.054.011.131.033.186.033.36 0 .568-.995.568-1.277 0-.335-.864-1.049-.864-2.444 0-2.9 2.232-4.955 5.12-4.955 2.482 0 4.32 1.396 4.32 3.96 0 1.914-.777 5.505-3.293 5.505-.907 0-1.684-.649-1.684-1.579 0-1.363.962-2.683.962-4.089 0-.854-.492-1.547-1.41-1.547-1.247 0-2.013 1.385-2.013 2.477 0 .606.077 1.277.35 1.829-.503 2.141-1.531 5.332-1.531 7.54 0 .68.098 1.351.164 2.033l.098.108.153-.054c1.838-2.488 1.772-2.975 2.603-6.23.449.843 1.608 1.297 2.527 1.297 3.872 0 5.611-3.732 5.611-7.096C14 2.337 10.872 0 7.437 0 3.697 0 0 2.466 0 6.458z" />
  </svg>
);

export default PinterestIcon;
