import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';

// Components
import HeadDetails from '../components/common/headContent';
import Header from '../components/global/header';
import Booking from '../components/global/booking';
import Freezer from '../components/common/freezer';
import Menu from '../components/global/menu';
import Footer from '../components/global/footer';
import ConsentManager from '../components/common/consentManager';

// Context
import { ContextProvider } from '../components/common/contextProvider';

// Styles
import Theme from '../assets/styles/theme';
import GlobalStyle from '../assets/styles/globals';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollPositionY: 0,
      bookingOpen: false,
      menuOpen: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = debounce(() => {
    const scrollPositionY = +window.scrollY;
    return this.setState({ scrollPositionY });
  }, 25);

  handleToggleBooking = () => {
    this.setState(state => ({ bookingOpen: !state.bookingOpen }));
  };

  handleToggleMenu = () => {
    this.setState(state => ({ menuOpen: !state.menuOpen }));
  };

  render() {
    const { children, location, noTransparent } = this.props;
    const { bookingOpen, menuOpen, scrollPositionY } = this.state;
    const isScrolling = !!scrollPositionY;

    return (
      <Fragment>
        <ThemeProvider theme={Theme}>
          <ContextProvider location={location}>
            <ConsentManager />
            <HeadDetails location={location} />
            <Fragment>
              <Header
                transparent={noTransparent ? '' : isScrolling}
                bookingOpen={bookingOpen}
                bookingToggle={this.handleToggleBooking}
                menuOpen={menuOpen}
                menuToggle={this.handleToggleMenu}
              />
              <Booking
                location={location}
                bookingOpen={bookingOpen}
                bookingToggle={this.handleToggleBooking}
              />
              <Freezer
                bookingOpen={bookingOpen}
                bookingToggle={this.handleToggleBooking}
              />
              <Menu menuOpen={menuOpen} />
              {children}
              <Footer />
              <GlobalStyle />
            </Fragment>
          </ContextProvider>
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default Layout;
