import React from 'react';

const TripAdvisorLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" id="logo" viewBox="0 0 239 38">
    <title>TripAdvisor Reviews</title>
    <path
      d="M42.27,27.828 C39.898,28.703 37.325,28.602 35.025,27.541 C32.729,26.482 30.984,24.594 30.11,22.225 L30.11,22.223 C29.233,19.85 29.333,17.279 30.389,14.982 C31.448,12.685 33.336,10.938 35.71,10.063 C40.608,8.255 46.064,10.768 47.872,15.664 C49.68,20.559 47.166,26.016 42.27,27.828 Z M15.351,28.365 C10.129,28.365 5.882,24.119 5.882,18.9 C5.882,13.679 10.129,9.432 15.351,9.432 C20.571,9.432 24.817,13.679 24.817,18.9 C24.817,24.119 20.571,28.365 15.351,28.365 Z M38.613,7.097 C37.369,7.134 36.111,7.361 34.88,7.816 C31.907,8.913 29.54,11.101 28.213,13.98 C27.615,15.279 27.276,16.652 27.169,18.037 C26.729,11.976 21.728,7.173 15.588,7.05 C18.985,5.6 22.887,4.81 27.118,4.81 C31.375,4.81 35.218,5.579 38.613,7.097 Z M48.51,11.877 C49.069,9.504 50.842,7.134 50.842,7.134 L42.928,7.13 C38.489,4.263 33.094,2.742 27.118,2.742 C20.92,2.742 15.323,4.3 10.908,7.185 L3.48,7.191 C3.48,7.191 5.232,9.532 5.802,11.888 C4.354,13.855 3.487,16.275 3.487,18.9 C3.487,25.441 8.809,30.762 15.351,30.762 C19.088,30.762 22.422,29.02 24.598,26.311 L27.122,30.09 L29.67,26.279 C30.815,27.736 32.29,28.916 34.022,29.715 C36.903,31.043 40.126,31.172 43.1,30.074 C49.237,27.803 52.385,20.965 50.119,14.834 C49.717,13.748 49.167,12.761 48.51,11.877 L48.51,11.877 Z"
      fill="#0A0B09"
    />
    <path
      d="M38.979,7.726 C39.586,7.726 40.184,7.775 40.771,7.871 C40.207,7.553 39.627,7.257 39.034,6.983 L38.887,7.727 C38.918,7.726 38.949,7.726 38.979,7.726 Z M15.176,6.857 C14.451,7.178 13.749,7.531 13.072,7.915 C13.803,7.763 14.561,7.683 15.336,7.682 L15.176,6.857 Z M6.558,8.939 C6.87,9.58 7.165,10.287 7.379,11.015 C8.202,10.183 9.153,9.479 10.201,8.936 L6.558,8.939 Z M43.925,8.881 C45.035,9.427 46.049,10.152 46.924,11.025 C47.14,10.275 47.443,9.543 47.764,8.883 L43.925,8.881 Z M21.963,6.992 C24.144,8.197 25.953,9.986 27.184,12.16 C28.43,9.984 30.23,8.218 32.416,7.02 C29.112,6.419 25.332,6.405 21.963,6.992 Z M15.351,11.182 C11.095,11.182 7.632,14.645 7.632,18.9 C7.632,23.154 11.095,26.615 15.351,26.615 C19.605,26.615 23.067,23.154 23.067,18.9 C23.067,14.645 19.605,11.182 15.351,11.182 Z M38.979,11.226 C38.071,11.226 37.175,11.387 36.315,11.704 C34.382,12.417 32.842,13.841 31.979,15.714 C31.118,17.586 31.037,19.683 31.752,21.616 C32.464,23.55 33.887,25.089 35.758,25.952 C37.627,26.814 39.735,26.898 41.665,26.187 C45.655,24.709 47.704,20.261 46.23,16.27 C45.116,13.253 42.203,11.226 38.979,11.226 Z M25.26,24.15 L27.123,26.94 L29.024,24.097 C28.815,23.69 28.629,23.267 28.468,22.83 C27.909,21.315 27.685,19.733 27.793,18.17 L26.543,18.166 C26.559,18.409 26.567,18.654 26.567,18.9 C26.567,20.796 26.094,22.583 25.26,24.15 Z M27.12,33.239 L24.354,29.097 C21.883,31.288 18.706,32.512 15.351,32.512 C7.844,32.512 1.737,26.405 1.737,18.9 C1.737,16.291 2.478,13.78 3.887,11.586 C3.277,9.861 2.091,8.256 2.078,8.238 L0,5.446 L10.396,5.436 C15.054,2.527 20.822,0.992 27.118,0.992 C33.161,0.992 38.791,2.508 43.435,5.381 L54.336,5.386 L52.244,8.183 C52.231,8.2 51.029,9.828 50.423,11.565 C50.969,12.407 51.417,13.299 51.76,14.227 C54.361,21.264 50.749,29.109 43.707,31.716 C40.312,32.969 36.579,32.821 33.29,31.304 C32.039,30.728 30.905,29.983 29.902,29.081 L27.12,33.239 L27.12,33.239 Z"
      fill="#FFFFFE"
    />
    <path
      d="M171.694,5.799 C171.694,7.056 172.718,8.081 173.977,8.081 C175.237,8.081 176.26,7.056 176.26,5.799 C176.26,4.54 175.237,3.515 173.977,3.515 C172.718,3.515 171.694,4.54 171.694,5.799"
      fill="#00A680"
    />
    <path
      d="M63.242,5.641 L59.782,6.832 C59.782,6.832 59.782,10.977 59.782,11.347 C59.417,11.347 55.944,11.347 55.944,11.347 L55.944,14.377 C55.944,14.377 59.307,14.377 59.671,14.377 C59.671,14.765 59.671,24.102 59.671,24.102 C59.671,28.731 61.25,30.615 65.115,30.615 C66.098,30.615 67.036,30.494 67.975,30.24 L68.127,30.199 L68.085,27.063 L67.817,27.156 C67.059,27.424 66.465,27.553 66.002,27.553 C64.186,27.553 63.51,26.527 63.51,23.77 C63.51,23.77 63.51,14.765 63.51,14.377 C63.88,14.377 67.94,14.377 67.94,14.377 L67.94,11.347 C67.94,11.347 63.88,11.347 63.51,11.347 C63.51,10.969 63.51,5.547 63.51,5.547 L63.242,5.641"
      fill="#FFFFFE"
    />
    <path
      d="M75.211,14.416 C75.211,13.349 75.211,11.347 75.211,11.347 L71.705,11.347 L71.705,30.285 L75.544,30.285 L75.544,20.779 C75.544,16.793 77.215,14.597 80.251,14.597 C80.688,14.597 81.116,14.662 81.633,14.81 L81.885,14.882 L82.009,11.142 L80.36,11.016 C78.134,11.016 76.336,12.216 75.211,14.416"
      fill="#FFFFFE"
    />
    <path
      d="M101.547,27.479 C98.508,27.479 96.62,24.883 96.62,20.705 C96.62,16.681 98.612,14.08 101.695,14.08 C104.664,14.08 106.436,16.543 106.436,20.67 C106.436,24.869 104.563,27.479 101.547,27.479 Z M102.616,10.904 C99.983,10.904 97.765,12.156 96.288,14.423 C96.288,13.513 96.288,11.347 96.288,11.347 L92.78,11.347 L92.78,38 L96.62,38 C96.62,38 96.62,28.691 96.62,27.666 C98.007,29.547 99.999,30.578 102.469,30.578 C107.325,30.578 110.46,26.602 110.46,20.447 C110.46,14.738 107.309,10.904 102.616,10.904 L102.616,10.904 Z"
      fill="#FFFFFE"
    />
    <path
      d="M125.055,22.293 C125.055,25.557 123.092,27.662 120.053,27.662 C118.197,27.662 116.899,26.441 116.899,24.691 C116.899,22.395 119.094,21.131 123.081,21.131 C123.081,21.131 124.721,21.131 125.055,21.131 C125.055,21.426 125.055,22.293 125.055,22.293 Z M121.678,10.904 C119.334,10.904 117.278,11.345 115.39,12.253 L115.272,12.308 L115.315,15.587 L115.614,15.419 C117.419,14.408 119.124,13.896 120.682,13.896 C123.461,13.896 125.055,15.49 125.055,18.27 C125.055,18.27 125.055,18.354 125.055,18.434 C124.715,18.434 122.86,18.434 122.86,18.434 C116.54,18.434 113.06,20.721 113.06,24.877 C113.06,28.213 115.571,30.543 119.167,30.543 C121.813,30.543 123.858,29.506 125.313,27.547 C125.313,28.305 125.313,30.285 125.313,30.285 L128.786,30.285 L128.786,18.822 C128.786,13.568 126.395,10.904 121.678,10.904 L121.678,10.904 Z"
      fill="#00A680"
    />
    <path
      d="M140.098,27.402 C137.172,27.402 135.355,24.852 135.355,20.742 C135.355,16.628 137.188,13.97 140.024,13.97 C143.202,13.97 145.097,16.502 145.097,20.742 C145.097,24.789 143.135,27.402 140.098,27.402 Z M145.097,2.311 C145.097,2.311 145.097,12.859 145.097,13.917 C143.682,11.949 141.711,10.904 139.173,10.904 C134.483,10.904 131.331,14.769 131.331,20.52 C131.331,26.443 134.525,30.578 139.1,30.578 C141.814,30.578 143.921,29.381 145.393,27.043 C145.393,27.992 145.393,30.285 145.393,30.285 L148.938,30.285 L148.938,2.311 L145.097,2.311 L145.097,2.311 Z"
      fill="#00A680"
    />
    <polyline
      fill="#00A680"
      points="166.097 11.347 160.649 22.277 155.618 11.347 151.29 11.347 160.571 30.285 170.106 11.347 166.097 11.347"
    />
    <polyline
      fill="#00A680"
      points="175.701 11.347 172.063 11.347 172.063 30.285 175.905 30.285 175.905 11.347 175.701 11.347"
    />
    <path
      d="M180.406,16.203 C180.406,18.869 182.237,20.272 185.614,21.92 C187.746,22.969 188.86,23.584 188.86,25.061 C188.86,26.572 187.49,27.553 185.371,27.553 C183.867,27.553 182.201,27.125 180.551,26.316 L180.271,26.18 L180.142,29.516 L180.276,29.57 C181.85,30.19 183.515,30.506 185.224,30.506 C189.92,30.506 192.956,28.268 192.956,24.803 C192.956,21.912 191.033,20.449 187.708,18.897 C185.267,17.753 184.247,17.082 184.247,15.869 C184.247,14.714 185.382,13.97 187.142,13.97 C188.728,13.97 190.277,14.324 191.743,15.021 L192.017,15.152 L192.191,11.88 L192.037,11.834 C190.442,11.365 188.87,11.127 187.365,11.127 C183.202,11.127 180.406,13.166 180.406,16.203"
      fill="#00A680"
    />
    <path
      d="M204.969,27.59 C201.781,27.59 199.636,24.852 199.636,20.779 C199.636,16.685 201.781,13.933 204.969,13.933 C208.21,13.933 210.304,16.619 210.304,20.779 C210.304,24.916 208.21,27.59 204.969,27.59 Z M204.932,10.904 C199.161,10.904 195.576,14.703 195.576,20.816 C195.576,26.93 199.161,30.729 204.932,30.729 C210.727,30.729 214.329,26.93 214.329,20.816 C214.329,14.703 210.727,10.904 204.932,10.904 L204.932,10.904 Z"
      fill="#00A680"
    />
    <path
      d="M221.414,14.416 C221.414,13.349 221.414,11.347 221.414,11.347 L217.907,11.347 L217.907,30.285 L221.746,30.285 L221.746,20.779 C221.746,16.793 223.419,14.597 226.453,14.597 C226.892,14.597 227.318,14.662 227.834,14.81 L228.086,14.882 L228.212,11.142 L226.562,11.016 C224.338,11.016 222.538,12.216 221.414,14.416"
      fill="#00A680"
    />
    <polyline
      fill="#FFFFFE"
      points="88.854 11.347 85.215 11.347 85.215 30.285 89.057 30.285 89.057 11.347 88.854 11.347"
    />
    <g transform="translate(11.000000, 3.000000)">
      <path
        d="M73.844,2.799 C73.844,4.056 74.872,5.081 76.129,5.081 C77.389,5.081 78.413,4.056 78.413,2.799 C78.413,1.54 77.389,0.515 76.129,0.515 C74.872,0.515 73.844,1.54 73.844,2.799"
        fill="#FFFFFE"
      />
      <path
        d="M0.658,5.276 C5.101,4.705 17.707,4.308 14.254,22.523 L18.17,22.205 C16.456,10.976 20.045,5.253 31.268,4.705 C12.653,-5.599 2.055,5.097 0.658,5.276"
        fill="#FDDD8E"
      />
    </g>
    <path
      d="M28.987,22.639 C31.023,28.162 37.163,30.988 42.684,28.951 C48.207,26.908 51.037,20.773 48.995,15.25 C46.954,9.723 40.82,6.902 35.295,8.939 C29.767,10.979 26.942,17.113 28.987,22.639"
      fill="#FFFFFE"
    />
    <path
      d="M26.015,18.9 C26.015,24.789 21.239,29.563 15.351,29.563 C9.458,29.563 4.684,24.789 4.684,18.9 C4.684,13.011 9.458,8.235 15.351,8.235 C21.239,8.235 26.015,13.011 26.015,18.9"
      fill="#FFFFFE"
    />
    <path
      d="M17.168,18.795 C17.168,19.881 16.284,20.768 15.198,20.768 C14.106,20.768 13.225,19.881 13.225,18.795 C13.225,17.705 14.106,16.824 15.198,16.824 C16.284,16.824 17.168,17.705 17.168,18.795"
      fill="#A72635"
    />
    <path
      d="M40.934,18.795 C40.934,19.881 40.053,20.768 38.965,20.768 C37.874,20.768 36.996,19.881 36.996,18.795 C36.996,17.705 37.874,16.824 38.965,16.824 C40.053,16.824 40.934,17.705 40.934,18.795"
      fill="#00A680"
    />
    <path
      d="M42.27,27.828 C39.898,28.703 37.325,28.602 35.025,27.541 C32.729,26.482 30.984,24.594 30.11,22.225 L30.11,22.223 C29.233,19.85 29.333,17.279 30.389,14.982 C31.448,12.685 33.336,10.938 35.71,10.063 C40.608,8.255 46.064,10.768 47.872,15.664 C49.68,20.559 47.166,26.016 42.27,27.828 Z M15.351,28.365 C10.129,28.365 5.882,24.119 5.882,18.9 C5.882,13.679 10.129,9.432 15.351,9.432 C20.571,9.432 24.817,13.679 24.817,18.9 C24.817,24.119 20.571,28.365 15.351,28.365 Z M38.613,7.097 C37.369,7.134 36.111,7.361 34.88,7.816 C31.907,8.913 29.54,11.101 28.213,13.98 C27.615,15.279 27.276,16.652 27.169,18.037 C26.729,11.976 21.728,7.173 15.588,7.05 C18.985,5.6 22.887,4.81 27.118,4.81 C31.375,4.81 35.218,5.579 38.613,7.097 Z M48.51,11.877 C49.069,9.504 50.842,7.134 50.842,7.134 L42.928,7.13 C38.489,4.263 33.094,2.742 27.118,2.742 C20.92,2.742 15.323,4.3 10.908,7.185 L3.48,7.191 C3.48,7.191 5.232,9.532 5.802,11.888 C4.354,13.855 3.487,16.275 3.487,18.9 C3.487,25.441 8.809,30.762 15.351,30.762 C19.088,30.762 22.422,29.02 24.598,26.311 L27.122,30.09 L29.67,26.279 C30.815,27.736 32.29,28.916 34.022,29.715 C36.903,31.043 40.126,31.172 43.1,30.074 C49.237,27.803 52.385,20.965 50.119,14.834 C49.717,13.748 49.167,12.761 48.51,11.877 L48.51,11.877 Z"
      fill="#0A0B09"
    />
    <path
      d="M15.204,22.637 C13.082,22.637 11.357,20.912 11.357,18.795 C11.357,16.677 13.082,14.955 15.204,14.955 C17.317,14.955 19.037,16.677 19.037,18.795 C19.037,20.912 17.317,22.637 15.204,22.637 Z M15.204,12.939 C11.97,12.939 9.34,15.566 9.34,18.795 C9.34,22.025 11.97,24.654 15.204,24.654 C18.429,24.654 21.054,22.025 21.054,18.795 C21.054,15.566 18.429,12.939 15.204,12.939 L15.204,12.939 Z"
      fill="#0A0B09"
    />
    <path
      d="M38.965,22.637 C36.846,22.637 35.124,20.912 35.124,18.795 C35.124,16.677 36.846,14.955 38.965,14.955 C41.082,14.955 42.803,16.677 42.803,18.795 C42.803,20.912 41.082,22.637 38.965,22.637 Z M38.965,12.939 C35.735,12.939 33.106,15.566 33.106,18.795 C33.106,22.025 35.735,24.654 38.965,24.654 C42.194,24.654 44.82,22.025 44.82,18.795 C44.82,15.566 42.194,12.939 38.965,12.939 L38.965,12.939 Z"
      fill="#0A0B09"
    />
    <path
      d="M234.662,10.77 L235.031,10.77 C235.482,10.77 235.707,10.609 235.707,10.279 C235.707,9.915 235.472,9.764 234.974,9.764 L234.662,9.764 L234.662,10.77 Z M235.142,9.114 C236.224,9.114 236.771,9.501 236.771,10.205 C236.771,10.693 236.478,11.07 235.97,11.22 L236.806,12.875 L235.669,12.875 L234.964,11.38 L234.662,11.38 L234.662,12.875 L233.628,12.875 L233.628,9.114 L235.142,9.114 Z M233.13,9.049 C232.576,9.606 232.285,10.318 232.285,11.07 C232.285,11.804 232.556,12.48 233.083,13.017 C233.619,13.57 234.324,13.871 235.076,13.871 C235.81,13.871 236.515,13.589 237.062,13.064 C237.597,12.546 237.89,11.839 237.89,11.07 C237.89,10.318 237.615,9.631 237.099,9.104 C236.552,8.552 235.857,8.259 235.087,8.259 C234.324,8.259 233.656,8.532 233.13,9.049 Z M237.55,8.673 C238.181,9.313 238.509,10.156 238.509,11.07 C238.509,12.021 238.154,12.875 237.494,13.505 C236.816,14.156 235.96,14.503 235.076,14.503 C234.156,14.503 233.298,14.136 232.64,13.458 C232.001,12.8 231.654,11.962 231.654,11.07 C231.654,10.156 232.021,9.274 232.697,8.607 C233.339,7.976 234.156,7.638 235.076,7.638 C236.027,7.638 236.892,7.996 237.55,8.673 L237.55,8.673 Z"
      fillOpacity="0.600000024"
      fill="#00A680"
    />
  </svg>
);

export default TripAdvisorLogo;
