import React from 'react';

const BookingIcon = (
  <svg
    viewBox="0 0 48 48"
    id="booking-icon"
    width="28"
    height="28"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Mountain Whispers Booking</title>
    <path
      d="M36.6726,8.6207H41.5V40H6.5V8.6207h4.8276m7.2414,0H29.4312M6.5,17.069h35m-35,7.6436h35m-35,7.6437h35M15.25,17.069V40M24,17.069V40m8.75-22.931V40M6.5,17.069V40m35-22.931V40M14.9483,5A3.6207,3.6207,0,1,0,18.569,8.6207,3.6206,3.6206,0,0,0,14.9483,5ZM33.0517,5a3.6207,3.6207,0,1,0,3.6207,3.6207A3.6207,3.6207,0,0,0,33.0517,5Z"
      fill="none"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

export default BookingIcon;
