import React from 'react';
import styled from 'styled-components';
import InfiniteCalendar, { Calendar, withRange } from 'react-infinite-calendar';

// Styles
import 'react-infinite-calendar/styles.css';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 20;
  &.dates-open {
    opacity: 1;
    visibility: visible;
  }
`;

const DateRangePicker = styled(InfiniteCalendar)`
  max-width: 400px;
  zoom: 0.78;
  width: 100% !important;
  z-index: 10 !important;
  @media (max-width: ${props => props.theme.responsive.small}) {
    max-width: 100%;
    display: block !important;
    max-height: 89%;
    overflow: hidden;
  }
  li {
    padding-left: inherit;
    line-height: inherit;
    padding-bottom: 15px;
    margin-bottom: inherit;
    &.Cal__Day__root {
      padding: 0;
    }
  }
  .Cal__Container__wrapper {
    .Cal__Weekdays__root {
      background-color: ${props =>
        props.theme.colors.global.background.dark} !important;
    }
    .Cal__Today__chevron {
      display: none !important;
    }
    .Cal__Today__root {
      background-color: ${props =>
        props.theme.colors.global.secondary} !important;
    }
    .Cal__Day__selection {
      background-color: ${props =>
        props.theme.colors.global.background.dark} !important;
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__betweenRange
      .Cal__Day__selection {
      background-color: ${props =>
        props.theme.colors.global.base.dark} !important;
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
      border-color: ${props => props.theme.colors.global.base.dark} !important;
    }
    .Cal__Day__range.Cal__Day__selected.Cal__Day__end .Cal__Day__selection {
      color: ${props => props.theme.colors.global.base.dark} !important;
    }
    .Cal__Day__root.Cal__Day__selected {
      color: ${props => props.theme.colors.global.base.dark} !important;
    }
    .Cal__Day__root.Cal__Day__today {
      color: ${props => props.theme.colors.global.primary} !important;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const Apply = styled.div`
  width: 50%;
  font-family: ${props => props.theme.fonts.header};
  background: ${props => props.theme.colors.global.background.light};
  color: ${props => props.theme.colors.global.base.dark};
  border-right: 1px solid ${props => props.theme.colors.global.border.dark};
  border-top: 1px solid ${props => props.theme.colors.global.border.dark};
  padding: 0.75em 1em;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    color: ${props => props.theme.colors.global.secondary};
  }
`;

const Cancel = styled.div`
  width: 50%;
  font-family: ${props => props.theme.fonts.header};
  background: ${props => props.theme.colors.global.background.light};
  color: ${props => props.theme.colors.global.base.dark};
  border-top: 1px solid ${props => props.theme.colors.global.border.dark};
  padding: 0.75em 1em;
  cursor: pointer;
  transition: all 0.3s ease-out;
  &:hover {
    color: ${props => props.theme.colors.global.secondary};
  }
`;

const today = new Date();

class DatesInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      datesOpen,
      startDate,
      endDate,
      datesChange,
      toggleDates
    } = this.props;
    return (
      <Wrapper className={datesOpen ? 'dates-open' : ''}>
        <DateRangePicker
          Component={withRange(Calendar)}
          selected={{
            start: startDate,
            end: endDate
          }}
          minDate={today}
          displayOptions={{
            showHeader: false
          }}
          onSelect={datesChange}
        />
        <ButtonWrapper>
          <Apply onClick={toggleDates}>Apply</Apply>
          <Cancel onClick={toggleDates}>Cancel</Cancel>
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

export default DatesInput;
