import React from 'react';
import Helmet from 'react-helmet';

// Config
import config from '../../../../config/website';

const HeadContent = () => (
  <Helmet>
    <html lang={config.siteLanguage} />
  </Helmet>
);

export default HeadContent;
