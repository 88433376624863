import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const PropertyLinks = styled.div`
  width: 50%;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    width: 100%;
    float: none;
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
  }
  ul {
    li {
      a {
        color: ${props => props.theme.colors.global.base.grey};
        @media (max-width: ${props => props.theme.responsive.medium}) {
          opacity: 0;
          transition-delay: 0s;
        }
      }
    }
  }
  h5 {
    margin-bottom: 1rem;
    font-size: 1.25em;
    color: ${props => props.theme.colors.global.primary};
    @media (max-width: ${props => props.theme.responsive.medium}) {
      opacity: 0;
      transition-delay: 0s;
      margin-top: 2rem;
      text-transform: uppercase;
    }
    a {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @media (max-width: ${props => props.theme.responsive.medium}) {
        justify-content: center;
      }
    }
  }
  &.active {
    display: block;
    visibility: visible;
    opacity: 1;
    max-height: 100%;
    transition: max-height 0.25s ease-in;
    ul {
      li {
        a {
          opacity: 1;
          transition-delay: 0.5s;
        }
      }
    }
    h5 {
      opacity: 1;
      transition-delay: 0.5s;
    }
  }
`;

const PropertyMenu = props => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulProperties(sort: { fields: [menuOrder], order: ASC }) {
          edges {
            node {
              id
              seoContent {
                menuTitle
                slug
              }
              menuOrder
            }
          }
        }
      }
    `}
    render={data => (
      <PropertyLinks className={props.footerMenu ? 'active' : ''}>
        <Link to="/mw-collection/">
          <h5>Properties</h5>
        </Link>
        <ul className="footerMenu">
          {data.allContentfulProperties.edges.map(({ node: property }) => (
            <li key={property.id}>
              <Link to={`/property/${property.seoContent.slug}/`}>
                {property.seoContent.menuTitle}
              </Link>
            </li>
          ))}
        </ul>
      </PropertyLinks>
    )}
  />
);

export default PropertyMenu;
