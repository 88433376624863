import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Icons
import {
  BookingIcon,
  EnquireIcon,
  CloseIcon
} from '../../../../assets/images/icons';

const Wrapper = styled.div`
  display: flex;
  height: 4.125rem;
  @media (max-width: ${props => props.theme.responsive.small}) {
    display: none;
  }
  > a,
  > div {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    font-size: 1.125rem;
    color: ${props => props.theme.colors.global.base.light};
    text-shadow: ${props => props.theme.fonts.style.shadow.dark};
    @media (max-width: ${props => props.theme.responsive.medium}) {
      padding: 1rem 1rem;
    }
    &.book-start {
      justify-content: center;
      position: relative;
      cursor: pointer;
      min-width: 0;
      &:after {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 2px;
        background: ${props => props.theme.colors.global.primary};
        content: '';
        z-index: -1;
        transition: all 0.6s ease-out;
      }
      svg {
        transition: all 150ms ease-in-out;
        path,
        line {
          stroke: ${props => props.theme.colors.global.base.light};
        }
      }
      &:hover {
        color: ${props => props.theme.colors.global.base.light};
        &:after {
          background: ${props => props.theme.colors.global.primary};
          height: 100%;
        }
      }
      &.bookingOpen {
        &:after {
          background: ${props => props.theme.colors.global.primary} !important;
          height: 100%;
        }
        &:hover {
          svg {
            transform: rotate(90deg);
          }
        }
      }
      .booking-close {
        opacity: 0;
        position: absolute;
        transform: rotate(180deg);
        transition-delay: 0s;
        width: 1.5rem;
        height: 1.5rem;
        right: 1rem;
        top: calc(50% - 0.75rem);
        @media (max-width: 91em) {
          right: 0;
          margin-right: 1rem;
        }
      }
    }
    &.enquireNow {
      justify-content: center;
      position: relative;
      min-width: 0;
      @media (max-width: ${props => props.theme.responsive.medium}) {
        display: none;
      }
      svg {
        path {
          fill: ${props => props.theme.colors.global.base.light};
        }
      }
      &:after {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 0px;
        background: ${props => props.theme.colors.global.primary};
        content: '';
        z-index: -1;
        transition: all 0.6s ease-out;
      }
      &:hover {
        color: ${props => props.theme.colors.global.base.light};
        &:after {
          background: ${props => props.theme.colors.global.primary};
          height: 100%;
          transition: all 0.6s ease-out;
        }
      }
    }
    svg {
      width: 1.75rem;
      height: 1.75rem;
      margin-right: 0.5rem;
    }
  }
  &.transparent {
    > a,
    > div {
      &.book-start,
      &.enquireNow {
        &:after {
          background: ${props => props.theme.colors.global.base.light};
        }
        &:hover {
          &:after {
            background: ${props => props.theme.colors.global.primary};
          }
        }
      }
    }
  }
`;

const HeaderRight = ({ transparent, bookingOpen, bookingToggle }) => (
  <Wrapper className={transparent ? 'transparent' : ''}>
    <Link to="/enquire" className="enquireNow">
      {EnquireIcon}
      Enquire Now
    </Link>
    <div
      role="button"
      className={`book-start ${bookingOpen ? 'bookingOpen' : ''}`}
      tabIndex={0}
      onClick={bookingToggle}
    >
      {bookingOpen ? CloseIcon : BookingIcon}
      Make a Booking
    </div>
  </Wrapper>
);

export default HeaderRight;
