import { css } from 'styled-components';

// Fonts
import LafabriqueRegular from '../../../fonts/LafabriqueRegular.woff2';
import LafabriqueBold from '../../../fonts/LafabriqueBold.woff2';
import ModernMTProDisplay from '../../../fonts/ModernMTPro.woff2';

const Fonts = css`
  @font-face {
    font-family: 'Lafabrique';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: url(${LafabriqueRegular}) format('woff2');
  }

  @font-face {
    font-family: 'Lafabrique';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: url(${LafabriqueBold}) format('woff2');
  }

  @font-face {
    font-family: 'ModernMTProDisplay';
    font-display: fallback;
    src: url(${ModernMTProDisplay}) format('woff2');
  }
`;

export default Fonts;
