import React from 'react';

const ScrollDownIcon = (
  <svg
    width="10"
    height="18"
    viewBox="0 0 10 18"
    id="scroll-down-icon"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Scroll Down</title>
    <g>
      <path d="M6.911 15.032a.49.49 0 0 0-.707 0l-1.675 1.675-1.674-1.675a.5.5 0 1 0-.708.708l2.039 2.013a.49.49 0 0 0 .707 0l2.018-2.018a.49.49 0 0 0 0-.707M9.08 9.08V4.538a4.54 4.54 0 1 0-9.08 0V9.08a4.54 4.54 0 1 0 9.08 0m-4.542 3.528a3.541 3.541 0 0 1-3.53-3.528V4.538a3.53 3.53 0 1 1 7.063 0V9.08a3.541 3.541 0 0 1-3.53 3.528" />
      <path d="M4.538 2.775a.507.507 0 0 0-.503.503v1.51a.507.507 0 0 0 1.01 0v-1.51a.507.507 0 0 0-.503-.507" />
    </g>
  </svg>
);

export default ScrollDownIcon;
