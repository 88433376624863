import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

// Components
import { BookingSelect, BookingDates, DatesInput, BookingCall } from '.';

// Templates
import { Button } from '../../../common/buttons';

const Wrapper = styled.form`
  text-align: center;
`;

const DateContainer = styled.div`
  position: relative;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  font-size: 1em;
  margin: 0;
  border: 1px solid ${props => props.theme.colors.global.primary};
  background: ${props => props.theme.colors.global.primary};
  color: ${props => props.theme.colors.global.base.light};
  &:hover {
    border: 1px solid ${props => props.theme.colors.global.secondary};
    background: ${props => props.theme.colors.global.secondary};
    color: ${props => props.theme.colors.global.base.light};
  }
  &:disabled {
    cursor: not-allowed;
    &:after {
      position: absolute;
      padding: 0.75em 1em;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: ${props => props.theme.colors.global.notice.error};
      border: 1px solid ${props => props.theme.colors.global.notice.error};
      opacity: 0;
      visibility: hidden;
      content: 'Choose a property';
      transition: all 0.3s ease-out;
    }
    &:hover {
      background: ${props => props.theme.colors.global.notice.error};
      border: 1px solid ${props => props.theme.colors.global.notice.error};
      &:after {
        opacity: 1;
        border: 1px solid ${props => props.theme.colors.global.notice.error};
        visibility: visible;
      }
    }
  }
`;

class BookingForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datesOpen: false,
      littleHotelierId: '',
      calendarDates: {
        startDate: moment().add(1, 'days'),
        endDate: moment().add(2, 'days')
      }
    };
    this.handlePropertySelect = this.handlePropertySelect.bind(this);
    this.handleToggleDates = this.handleToggleDates.bind(this);
    this.handleDatesChange = this.handleDatesChange.bind(this);
  }

  componentDidMount() {
    const { location, properties } = this.props;
    const pathname = location.pathname;
    const filteredArray = properties.edges.filter(
      ({ node }) => `/${node.seoContent.slug}` === pathname
    );
    if (
      filteredArray.length !== 0 &&
      this.setState({
        littleHotelierId: filteredArray[0].node.information.littleHotelierId
      })
    );
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  handlePropertySelect = e => {
    this.setState({ littleHotelierId: e.target.value });
  };

  handleToggleDates = () => {
    this.setState(state => ({ datesOpen: !state.datesOpen }));
  };

  handleDatesChange(data) {
    if (data.eventType === 3)
      this.setState({
        calendarDates: {
          startDate: data.start,
          endDate: data.end
        }
      });
  }

  render() {
    const { datesOpen, littleHotelierId, calendarDates } = this.state;
    const isEnabled = littleHotelierId.length > 0;
    return (
      <React.Fragment>
        <Wrapper
          method="POST"
          action={`https://apac.littlehotelier.com/properties/${littleHotelierId}?check_in_date=${moment(
            calendarDates.startDate
          ).format('YYYY-MM-DD')}&check_out_date=${moment(
            calendarDates.endDate
          ).format('YYYY-MM-DD')}`}
          target="_blank"
        >
          <BookingSelect
            littleHotelierId={littleHotelierId}
            handlePropertySelect={this.handlePropertySelect}
          />
          <DateContainer>
            <BookingDates
              isEnabled={isEnabled}
              startDate={calendarDates.startDate}
              endDate={calendarDates.endDate}
              toggleDates={this.handleToggleDates}
            />
          </DateContainer>
          {isEnabled && (
            <DatesInput
              littleHotelierId={littleHotelierId}
              datesOpen={datesOpen}
              startDate={calendarDates.startDate}
              endDate={calendarDates.endDate}
              toggleDates={this.handleToggleDates}
              datesChange={this.handleDatesChange}
            />
          )}
          <SubmitButton disabled={!isEnabled} type="submit">
            Book Now
          </SubmitButton>
          <BookingCall />
        </Wrapper>
      </React.Fragment>
    );
  }
}

export default BookingForm;
