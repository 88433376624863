import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.a`
  display: none;
  text-transform: uppercase;
  font-family: ${props => props.theme.fonts.header};
  padding: 1em 0;
  position: relative;
  transition: all 0.3s ease-out;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span {
    width: 1.75em;
    height: 1.75em;
    display: block;
    border: 1px solid ${props => props.theme.colors.global.primary};
    position: relative;
    margin-right: 1em;
    &:before {
      transition: all 0.3s ease-out;
      content: '';
      width: 1.25em;
      height: 1px;
      background: ${props => props.theme.colors.global.primary};
      position: absolute;
      left: calc(0.25em - 1px);
      top: 50%;
    }
    &:after {
      transition: all 0.3s ease-out;
      content: '';
      height: 1.25em;
      width: 1px;
      background: ${props => props.theme.colors.global.primary};
      position: absolute;
      left: 50%;
      top: calc(0.25em - 1px);
    }
    &.less {
      &:after {
        opacity: 0;
      }
    }
  }
`;

const ToggleMenu = ({ footerMenu, handleToggleFooterMenu }) => (
  <Wrapper
    href="javascript:;"
    className={footerMenu ? 'active' : ''}
    onClick={handleToggleFooterMenu}
  >
    <span className={footerMenu ? 'less' : ''} />
    More Information
  </Wrapper>
);

export default ToggleMenu;
