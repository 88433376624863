import ArrowRightIcon from './files/arrowRightIcon';
import ArrowStoriesIcon from './files/arrowStoriesIcon';
import BookingDropdownIcon from './files/bookingDropdownIcon.svg';
import BookingIcon from './files/bookingIcon';
import CloseIcon from './files/closeIcon';
import ContactIcon from './files/contactIcon';
import DatesArrowIcon from './files/datesArrowIcon';
import DoubleArrowIcon from './files/doubleArrowIcon';
import EnquireIcon from './files/enquireIcon';
import FacebookIcon from './files/facebookIcon';
import InstagramIcon from './files/instagramIcon';
import LinkedInIcon from './files/linkedInIcon';
import PinterestIcon from './files/pinterestIcon';
import ScrollDownIcon from './files/scrollDownIcon';
import ScrollLeftIcon from './files/scrollLeftIcon';
import ScrollRightIcon from './files/scrollRightIcon';
import TripAdvisorIcon from './files/tripAdvisorIcon';
import TwitterIcon from './files/twitterIcon';
import YoutubeIcon from './files/youtubeIcon';

export {
  ArrowRightIcon,
  ArrowStoriesIcon,
  BookingDropdownIcon,
  BookingIcon,
  CloseIcon,
  ContactIcon,
  DatesArrowIcon,
  DoubleArrowIcon,
  EnquireIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  PinterestIcon,
  ScrollDownIcon,
  ScrollLeftIcon,
  ScrollRightIcon,
  TripAdvisorIcon,
  TwitterIcon,
  YoutubeIcon
};
