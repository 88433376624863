import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';

const Wrapper = styled.p`
  margin-bottom: 0;
  color: ${props => props.theme.colors.global.base.grey};
  font-size: 1em;
  font-family: ${props => props.theme.fonts.header};
  justify-content: flex-end;
  a {
    color: ${props => props.theme.colors.global.base.grey};
  }
`;

const ContactPhone = () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulCompanyInformation(
          id: { eq: "bb2bf43d-b156-5f8d-acca-daa9163189cd" }
        ) {
          phone
        }
      }
    `}
    render={data => (
      <Wrapper>
        <a href={`tel: +61 ${data.contentfulCompanyInformation.phone}`}>
          {data.contentfulCompanyInformation.phone}
          &nbsp;(Intl. +61&nbsp;
          {data.contentfulCompanyInformation.phone})
        </a>
      </Wrapper>
    )}
  />
);

export default ContactPhone;
