import styled from 'styled-components';

const WrapperHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 16;
  height: 4.125em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ${props => props.theme.fonts.header};
  background: ${props => props.theme.colors.header.background};
  transition: all 300ms ease-in-out;
  &.transparent {
    background: ${props => props.theme.colors.header.transparent};
    a {
      color: #fff;
      text-shadow: ${props => props.theme.fonts.style.shadow.dark};
    }
  }
  &.menuOpen {
    background: ${props => props.theme.colors.header.background};
  }
  svg {
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
  }
  a {
    font-size: 1.125rem;
    color: ${props => props.theme.colors.global.base.dark};
  }
`;

export default WrapperHeader;
