module.exports = {
  pathPrefix: '/',
  siteTitle: 'Mountain Whispers - Blue Mountains, Sydney, NSW.', // Navigation and Site Title
  siteTitleAlt:
    'Mountain Whispers - Luxury holiday homes in the Blue Mountains, Sydney, New South Wales', // Alternative Site title for SEO
  siteTitleShort: 'Mountain Whispers', // short_name for manifest
  siteHeadline:
    'Luxury escape and accommodation holiday homes in the Blue Mountains, Sydney, New South Wales', // Headline for schema.org JSONLD
  siteUrl: 'https://www.mountainwhispers.com.au', // Domain of site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: './src/assets/images/favicon.png', // Used for SEO, sharing and manifest
  siteDescription:
    'Luxury escape and accommodation holiday homes in the Blue Mountains, Sydney, New South Wales',
  author: 'Discovr Bookings', // Author for schema.org JSONLD
  themeColor: '#e7b679'
};
