import React from 'react';
import Slider from 'react-slick';
import moment from 'moment';
import styled from 'styled-components';

// Icon
import { TripAdvisorIcon } from '../../../../assets/images/icons';

// Images
import loadingImage from '../../../../assets/images/loading-animation.jpg';

// Styles
import { TextLoading } from '../../../../assets/styles/globals/files';

const Wrapper = styled.div`
  width: 50%;
  @media (max-width: ${props => props.theme.responsive.small}) {
    width: 100%;
    float: none;
  }
  h4 {
    background: url(${loadingImage}) repeat-y;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${TextLoading} 80s linear infinite;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    display: block;
  }
  .footerReviews {
    width: 100%;
    outline: none;
    @media (max-width: ${props => props.theme.responsive.small}) {
      width: 100%;
    }
    .reviewDate {
      display: flex;
      font-size: 15px !important;
      line-height: 1.2em;
      font-weight: 700;
      @media (max-width: ${props => props.theme.responsive.medium}) {
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 1em;
      }
      svg {
        margin: 4px 5px 0 0;
        height: 13px;
        fill: ${props => props.theme.colors.global.base.light};
        @media (max-width: ${props => props.theme.responsive.small}) {
          height: 30px;
        }
      }
      img {
        margin: 4px 5px 0 0;
        height: 13px;
        @media (max-width: ${props => props.theme.responsive.small}) {
          height: 15px;
        }
      }
      h5 {
        margin-left: 5px;
        margin-bottom: 10px;
        font-size: 1.125rem;
        color: ${props => props.theme.colors.global.base.grey};
        @media (max-width: ${props => props.theme.responsive.small}) {
          margin-left: 0;
          width: 100%;
          margin-top: 0;
        }
      }
    }
    a {
      @media (max-width: 48em) {
        padding-bottom: 1.5em;
      }
    }
    h2 {
      font-size: 1.1em;
      color: ${props => props.theme.colors.global.primary};
      margin-bottom: 5px;
    }
    p {
      display: block;
      font-family: ${props => props.theme.fonts.header};
      font-size: 1em;
      color: ${props => props.theme.colors.global.base.grey};
      margin-bottom: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
`;

class FooterReviews extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      reviewData: []
    };
  }

  componentDidMount() {
    const self = this;
    const proxyurl = 'https://cors-anywhere.herokuapp.com/';
    const url = `//api.tripadvisor.com/api/partner/2.0/location/1456593?key=2380ee0b7c304f6eb06625ae38184c8b`;
    // FaceBook const url = `https://graph.facebook.com/v3.1/210765039023834/ratings?access_token=EAADvljg8VQEBAGOhV3a1GyfVKWhr7ZBPXT5R5PHWt3k0mrsj94ZA3ZAPF9Q5VqwPe1yp9V5FMvetwespQEoHBDQiCCnUwnkzpEdsA6f2JxBG4zYRSb1ZCE4XeXUVh16zuRjFagns4pTUmFbOPDnbU7lliuFQ5cdphr0nnWZBoAAZDZD`;
    fetch(proxyurl + url)
      .then(data => data.json())
      .then(data => {
        !this.isCancelled &&
          self.setState({
            reviewData: data,
            isLoading: false
          });
      });
  }

  componentWillUnmount() {
    this.isCancelled = true;
  }

  render() {
    const settings = {
      dots: false,
      arrows: false,
      fade: true,
      infinite: true,
      speed: 500,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const { isLoading, reviewData } = this.state;

    return (
      <Wrapper>
        {isLoading && <h4>Loading Reviews</h4>}
        {reviewData !== null && !isLoading && (
          <Slider {...settings} className="footerReviews">
            {reviewData.reviews.map(i => {
              return (
                <a
                  href={i.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={i.id}
                >
                  <div className="reviewDate">
                    {TripAdvisorIcon}
                    <img
                      src={i.rating_image_url.replace(
                        /^http:\/\//i,
                        'https://'
                      )}
                      alt={i.title}
                    />
                    <h5>{moment(i.published_date).format('MMM D, YYYY')}</h5>
                  </div>
                  <h2>{i.title}</h2>
                  <p>{i.text}</p>
                </a>
              );
            })}
          </Slider>
        )}
      </Wrapper>
    );
  }
}

export default FooterReviews;
