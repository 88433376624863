import React from 'react';
import styled from 'styled-components';

// Components
import {
  ContactAddress,
  ContactTitle,
  ContactPhone,
  ContactEmail,
  ContactSocial
} from '.';

const Wrapper = styled.div`
  width: 50%;
  text-align: right;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    text-align: center;
    width: 100%;
    float: none;
    -webkit-box-ordinal-group: 2;
    order: 1;
  }
`;

const ContactDetails = () => (
  <Wrapper>
    <ContactTitle />
    <ContactAddress />
    <ContactPhone />
    <ContactEmail />
    <ContactSocial />
  </Wrapper>
);

export default ContactDetails;
