import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left: 1.875rem;
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  @media (max-width: ${props => props.theme.responsive.small}) {
    padding-left: 1rem;
  }
  .nav-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    @media (max-width: ${props => props.theme.responsive.small}) {
      position: absolute;
      right: 1.875rem;
      top: 50%;
      transform: translateY(-50%);
    }
    .nav-icon {
      margin-right: 1rem;
      height: 1.25rem;
      width: 1.25rem;
      display: inline-block;
      position: relative;
      transition: all 0.3s ease-out 0s;
      span {
        position: absolute;
        height: 1px;
        width: 2rem;
        background: ${props => props.theme.colors.global.base.light};
        display: block;
        left: 0;
        transition: all 0.3s ease-out 0s;
        &:nth-child(1) {
          top: 0;
        }
        &:nth-child(2) {
          top: 50%;
        }
        &:nth-child(3) {
          bottom: 0;
        }
      }
    }
    &:hover {
      .nav-icon {
        height: 1.5rem;
        width: 1.5rem;
        @media (max-width: ${props => props.theme.responsive.small}) {
          height: 1.25rem;
          width: 1.25rem;
        }
      }
    }
    .menu-text {
      transition: all 0.3s ease-out;
      transition-delay: 300ms;
      font-size: 1.25em;
      margin-left: 0.25em;
      color: ${props => props.theme.colors.global.base.light};
      @media (max-width: ${props => props.theme.responsive.small}) {
        display: none;
      }
    }
  }
  &.menuOpen {
    .nav-toggle {
      text-shadow: none;
      .nav-icon {
        height: 1.25em;
        &:hover {
          transform: rotate(180deg);
        }
        span {
          box-shadow: none;
          background: rgb(252, 252, 252);
          &:nth-child(1) {
            transform: rotate(45deg);
            transform-origin: left top;
            top: -0.125em;
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
            transform-origin: left bottom;
            bottom: -0.125em;
          }
        }
      }
      .menu-text {
        opacity: 0;
        animation-fill-mode: none;
      }
    }
  }
  &.transparent {
    .nav-toggle {
      .nav-icon {
        span {
          position: absolute;
          height: 1px;
          width: 2rem;
          display: block;
          left: 0;
          background: ${props => props.theme.colors.global.base.light};
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
`;

const HeaderLeft = ({ transparent, menuOpen, menuToggle }) => (
  <Wrapper
    onClick={menuToggle}
    className={`${transparent ? 'transparent' : ''} ${
      menuOpen ? 'menuOpen' : ''
    }`}
  >
    <div className="nav-toggle">
      <i className="nav-icon">
        <span />
        <span />
        <span />
      </i>
      <span className="menu-text">Menu</span>
    </div>
  </Wrapper>
);

export default HeaderLeft;
