import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

// Images
import { LogoAlt } from '../../../../assets/images/logos';

const Wrapper = styled(Link)`
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  transform: translate(-50%, -50%);
  transition-delay: 300ms;
  color: ${props => props.theme.colors.global.base.light};
  svg {
    fill: ${props => props.theme.colors.global.base.light};
    width: 17rem;
    @media (max-width: ${props => props.theme.responsive.medium}) {
      width: 15rem;
    }
    @media (max-width: ${props => props.theme.responsive.small}) {
      width: 13rem;
    }
  }
  &.menuOpen {
    @media (min-width: ${props => props.theme.responsive.small}) {
      left: 5em;
      transform: translate(0px, -50%);
    }
  }
`;

const HeaderLogo = ({ menuOpen }) => (
  <Wrapper
    className={menuOpen ? 'menuOpen' : ''}
    title="Mountain Whispers | Blue Mountains, NSW Australia"
    to="/"
  >
    {LogoAlt}
  </Wrapper>
);

export default HeaderLogo;
