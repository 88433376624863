import styled from 'styled-components';

const FooterBottom = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.3s ease-out;
  @media (max-width: ${props => props.theme.responsive.medium}) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    text-align: center;
    border-top: none;
    padding-top: 0;
  }
`;

export default FooterBottom;
