import React from 'react';
import styled from 'styled-components';

// Icons
import { CloseIcon } from '../../../../assets/images/icons';

const Wrapper = styled.span`
  display: none;
  position: absolute;
  top: 0.5em;
  right: 0.75em;
  cursor: pointer;
  @media (max-width: ${props => props.theme.responsive.small}) {
    display: block;
  }
  svg {
    width: 1.5rem;
    height: 1.5rem;
    line {
      stroke: ${props => props.theme.colors.global.base.dark};
    }
  }
`;

const BookingClose = ({ bookingToggle }) => (
  <Wrapper onClick={bookingToggle}>{CloseIcon}</Wrapper>
);

export default BookingClose;
